import React from "react";
import cx from "classnames";
import shakingHands from "../images/shakingHands.jpg";
import home from "../images/CandorLogo.png";
import { Helmet } from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

const StyledButton = ({ className, children, ...props }) => {
  className = cx(
    "py-2 px-4 bg-indigo-700 hover:bg-indigo-600 text-base text-white font-bold uppercase rounded shadow-md hover:-translate-1",
    className
  );
  return (
    <button className={className} {...props}>
      {children}
    </button>
  );
};

const Service = ({ title}) => {
  return (
    <div className="w-full sm:w-1/2 md:w-1/3 p-2">

      <div className="text-2xl text-indigo-700">
      {title}
      </div>
    </div>
  );
};

function Index({ data }) {
  const services = data.services.edges;

  return (
    <Layout headerClass="relative bg-white">
       <SEO title="Home" /> 




      <div
        className="min-h-screen pt-12 sm:pt-16 md:pt-32 -mt-12 sm:-mt-8 md:-mt-24 lg:-mt-28 mb-20 flex flex-col items-center"
      >
          <div className="lg:px-64 md:px-32 sm:px-16">
            <img src={shakingHands} />
          </div>
          
          <div className="pt-6 sm:pt-8 md:pt-16 font-serif font-hairline self-start">
            <h1 className="text-3xl md:text-5xl text-indigo-700 leading-tight">
              Candor Investigation Services
            </h1>

            </div>
       
       
        <div className="pt-4 sm:pt-8 md:pt-8 text-gray-800">


          Candor Investigation Services commenced trading in 2009 and was founded by company director Phil Doudle.
          He has 33 years experience working in SA Police in areas such as police major crash investigation, patrols, traffic, and 19 years experience working as an investigator in the private sector investigating CTP Claims, General Insurance Claims, Public Liability and Work Cover Claims. 

          Now with his work being recognised by major insurance companies, the business has grown
          and can offer a range of services to suit your investigation needs.


        </div>

         <div> 

          <p className="py-6 sm:py-8 md:py-8 text-3xl text-gray-800 self-center">
            Our Services
          </p>

          </div>

          <div className="flex flex-wrap">
            {services.map(({ node }) => (
              <Service
                title={node.frontmatter.title}
              >
              </Service>
            ))}
          </div>

          <div className="self-center mt-8">
            <Link to="/services">
              <StyledButton>View all services</StyledButton>
            </Link>
          </div>
        </div>

    </Layout>
  );
}

export const query = graphql`
  query {
    services: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/services/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
        }
      }
    }
  }
`;

export default Index;
